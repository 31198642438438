.seconday-appbar {
    background-color: $secondary-main;
    padding: 0.5rem 6rem !important;
    font-size: 0;
    display: flex;

    span, p, button {
        // color: white;
        // font-size: 1.2rem;
        text-transform: none;
    }

    svg {
        color: white;
    }

    .MuiButtonBase-root {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}

@media only screen and (max-width: 600px) {
    .seconday-appbar {
        // width: 100% !important;
        padding: 0 0.5rem !important;
        display: block;
    }
}
@media (min-width: 600px) and (max-width: 900px) {
    .seconday-appbar {
        padding: 0.5rem 1rem !important;
        display: block;
    }
}

.drawer-wrapper {
    .MuiDrawer-paper {
        height: 100% !important;
    }
}