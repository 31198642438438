.layout-view {
    width: 100%;
    font-size: 2rem;
    // position: relative;
    left: 0;
    top: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .main-view {
        flex: 1;
    }

    .banner-section {
        width: 100%;

        .banner-content {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .banner-overlay {
            width: 100%;
            height: fit-content;
            position: relative;

            .banner-image {
                width: 100%;
            }

            .banner-detail {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .MuiGrid-container {
                    height: 100%;
                    width: 70%;
                    font-size: 0.5rem;

                    .title {
                        font-size: 1.5rem;
                    }

                    .description {
                        font-size: 0.3rem;
                        line-height: 1;
                    }

                    .explore-btn {
                        width: fit-content;
                        padding: 0.3rem 1rem;
                        font-size: 0.7rem;
                    }
                }
            }

            @media only screen and (max-width: 900px) {
                .banner-detail {
                    display: flex;
                    justify-content: center;

                    .MuiGrid-container {
                        width: 90%;
                    }
                }
            }
        }

        .gradient-content {
            background-color: #29aecc;
            /* For browsers that do not support gradients */
            background-image: linear-gradient(to bottom left, #29aecc, white);
        }

        .title-section {
            padding: 5rem 6rem;
            height: 100%;
            min-height: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .title-content-section {
                padding: 2rem 3rem;
                background-color: rgba(255, 255, 255, 0.6);
                width: fit-content;
            }

            .title {
                font-size: 2.4rem;
            }

            @media (min-width: 1200px) and (max-width: 1400px) {
                .title {
                    font-size: 2.2rem;
                }
            }

            @media (min-width: 900px) and (max-width: 1200px) {
                .title {
                    font-size: 2rem;
                }

                .title-content-section {
                    padding: 2rem;
                }
            }

            @media (min-width: 480px) and (max-width: 900px) {
                .title {
                    font-size: 2rem !important;
                }
            }

            @media only screen and (max-width: 480px) {
                .title {
                    font-size: 1.6rem !important;
                }
            }

            .description {
                font-size: 1.2rem;
                line-height: 1.5;
            }

            .explore-btn {
                width: fit-content;
                padding: 0.5rem 2rem;
            }
        }

        @media (max-width: 1000px) {
            .title-section {
                padding: 3rem 2rem;
                min-height: unset;
            }
        }

        @media screen and (max-width: 480px) {
            .title-section {
                padding: 1rem 1rem;
            }
        }
    }

    .draft-section {
        padding: 3rem 0;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .title-section {
            width: 70%;

            .description {
                font-size: 1rem;
                line-height: 1.5rem;
            }
        }

        @media screen and (max-width: 480px) {
            .title-section {
                width: 90%;
            }
        }

        .content-section {
            display: flex;
            flex-direction: row;
            padding: 0 0;
            width: 100%;

            .content-card {
                margin-right: 1rem;

                .MuiCardMedia-root {
                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }

                .MuiCardContent-root {
                    padding: 0 1rem;
                }

                .MuiCardActions-root {
                    padding: 0 1rem 0.7rem 1rem;
                    display: block;

                    button {
                        width: 100%;
                    }
                }
            }

            @media screen and (max-width: 600px) {
                .content-card {
                    width: 95%;

                    .MuiCardMedia-root {
                        width: 100%;
                    }
                }
            }
        }
    }
}