.no-box-shadow {
    box-shadow: none !important;
}

.container {
    // padding-top: 1rem;
}

.center {
    margin: auto;
    width: 50%;
    padding: 10px;
    text-align: center;
}

.MuiDrawer-paper {
    background-color: $primary-main !important;
    // font-size: 1.5rem;
    span, p, svg {
        color: white;
    }
}

.description {
    color: rgba(0,0,0,0.5);
}

.content-container {
    padding: 1rem 5rem;
    .divider {
        margin-top: 1rem;
    }

    .main-content-wrapper {
        padding: 3rem 0;
    }
}