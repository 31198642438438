.cart-wrapper {
    padding: 1rem 0 !important;
    .stepper-header {
        // border: 1px solid red;
        padding: 2rem;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        // background-color: rgba(21, 33, 62, 0.2);
        background-color: rgba(16, 52, 96, 0.23);
        // background-color: rgba(21, 33, 62);

        .stepper-header-content {
            text-align: center;

            svg {
                margin: 0 1rem;
            }
            .active {
                font-weight: 700;
                font-size:1.5rem;
            }
        }
    }
    @media only screen and (max-width: 900px) {
        .stepper-header {
            padding: 1rem;
            align-items: center;
            border-radius: 5px;
            .stepper-header-content {
                span {
                    font-size: 1.5rem;
                }
                .inactive {
                    display: none;
                }
                svg {
                    display: none;
                }
            }
        }
        .mobile-table-wrapper {
            // border: 2px solid red;
            .product-detail {
                .detail-wrapper {
                    flex-grow: 1;
                    margin-left: 1rem;
                    .price {
                        font-size: 1rem;
                    }
                }
                .icon-wrapper {
                    align-items: baseline !important;
                    button {
                        svg {
                            height: 1.5rem;
                            width: 1.5rem;
                        }
                    }
                }
                .image-wrapper {
                    // border: 1px solid red;
                    width: 20%;
                    height: unset !important;
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                        height: unset !important;
                        max-height: 7rem;
                        object-fit: contain;
                    }
                }
            }
        }
    }
    @media (min-width:900px) and (max-width: 1200px) {
        .stepper-header {
            padding: 0.5rem;
            align-items: center;
            border-radius: 5px;
            .stepper-header-content {
                padding: 1rem 0.5rem;
                svg {
                    font-size: 0.8rem;
                    margin: 0 0.5rem;
                }
                span {
                    font-size: 1.25rem;
                }
            }
        }
    }
    @media (min-width:1200px) and (max-width: 1400px) {
        .stepper-header {
            padding: 0.3rem;
            align-items: center;
            border-radius: 5px;
            .stepper-header-content {
                padding: 1rem 0.5rem;
                svg {
                    font-size: 1rem;
                }
                span {
                    font-size: 1.7rem;
                }
            }
        }
    }

    .cart-content {
        // border: 1px solid red;
        padding-top: 3rem;

        .header {
            padding: 0.5rem 0;
        }

        .greyout {
            opacity: 0.2;
        }
          
        .cart-table {
            margin: 0 1rem 0 0;
            border: 2px solid green;
            border: unset;
            box-shadow: unset;

            .cart-table-header {
                tr {
                    padding: 0.5rem;
                }
            }
        }

        .button-wrapper {
            height: inherit;
            display: flex;
            align-items: center;
            button {
                border: 2px solid black;
                font-weight: 600;
            }
        }

        .coupon-wrapper {
            max-width: 600px;
            margin: 1rem 0;
            .form-wrapper {
                padding: 1rem 1rem 1rem 0;
            }
        }
        .coupon-conatiner {
            padding-top: 80px;
        }

        @media only screen and (max-width: 1200px) {
            .coupon-conatiner {
                padding-top: 20px;
            }
        }

        .button-group {
            padding: 1rem 0;
            button {
                min-width: unset;
                border-radius: 0;
                color: white;
                background-color: $primary-main;
                &:first-child {
                    background-color: unset;
                    color: unset;
                    border-radius: 0.5rem 0 0 0.5rem;
                }
                &:last-child {
                    background-color: unset;
                    color: unset;
                    border-radius: 0 0.5rem 0.5rem 0;
                }
            }
        }
        .price {
            font-size: 1.2rem;
        }

        .product-detail {
            padding: 0.4rem 0.2rem;
            // border: 2px solid green;
            display: flex;
            flex-direction: row;
            
            .icon-wrapper {
                // border: 1px solid red;
                display: flex;
                align-items: center;
                svg {
                    height: 1rem;
                    width: 1rem;
                }
            }
            .image-wrapper {
                height: 100px;
                padding: 0.2rem;
                img {
                    height: 100%;
                }
            }
            .detail-wrapper {
                padding: 0.5rem;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .inventory-text {
                    margin-top: 0.5rem;
                    font-size: 1rem;
                    color: var(--mui-palette-error-main);
                }
            }
        }

        .MuiTableCell-root {
            padding: 0;
        }

        .error-text {
            color: var(--mui-palette-error-main);
            font-weight: 400;
            font-style: italic;
            font-size: 0.9rem;
            line-height: 1.66;
            letter-spacing: 0.03333em;
            text-align: left;
            margin-top: 1rem;
        }

        .cart-summary {
            // margin: 0 0 0 2rem;
            background-color: rgba(16, 52, 96, 0.1);
            padding: 1rem;
            display: flex;
            flex-direction: column;
            text-align: center;
            border-radius: 2%;

            .title {
                font-weight: 300;
                font-size: 1.4rem;
            }

            .divider {
                margin: 1rem 0;
            }

            .calc-wrapper {
                // border: 1px solid red;
                display: flex;
                justify-content: space-between;
                padding-bottom: 1.5rem;
                .description {
                    font-size: 1rem;
                }
            }
            .button-wrapper {
                padding: 0.5rem 0 0 0;
                width: 100%;
                button, a {
                    width: 100%;
                }
            }
        }
    }

    .empty-wrapper {
        width: 100%;
        margin-top: 2rem;
        background-color: rgba(16, 52, 96, 0.1);
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 3rem;
        text-align: center;


        .icon-wrapper {
            svg {
                color: rgba(16, 52, 96, 0.3);
                font-size: 8rem;
            }
        }
        .title {
            font-weight: 600;
        }
        .description {
            font-size: 1rem;
        }
        .button-wrapper {
            padding-top: 1rem;
        }
    }
}