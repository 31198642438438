// Raleway + Roboto
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,800;1,300;1,400;1,500;1,600;1,800&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$Raleway: 'Raleway', sans-serif;
$Roboto: 'Roboto', sans-serif;
$Mulish: 'Mulish', sans-serif;

@mixin raleway {
    font-family: $Raleway;
    font-weight: 400;
}

@mixin raleway-bold {
    font-family: $Raleway;
    font-weight: 600;
}

@mixin Roboto {
    font-family: $Roboto;
}

@mixin mulish-light {
    font-family: $Mulish;
    font-weight: 200;
}

@mixin mulish {
    font-family: $Mulish !important;
    font-weight: 400;
}

@mixin mulish-bold {
    font-family: $Mulish;
    font-weight: 600;
}

@mixin mulish-xbold {
    font-family: $Mulish;
    font-weight: 800;
}

@mixin mulish-italic {
    font-family: $Mulish;
    font-weight: 800;
    font-style: italic;
}