$bg-theme: #282c34;
$highlight: #e31b6d;
$title-theme: #444649;
$theme: #04c2c9;

// MUI Color Palette 
$primary-light: var(--mui-palette-primary-light);
$primary-main: var(--mui-palette-primary-main);
$primary-dark: var(--mui-palette-primary-dark);
$secondary-light: var(--mui-palette-secondary-light);
$secondary-main: var(--mui-palette-secondary-main);
$secondary-dark: var(--mui-palette-secondary-dark);
$error-light: var(--mui-palette-error-light);
$error-main: var(--mui-palette-error-main);
$error-dark: var(--mui-palette-error-dark);
$warning-light: var(--mui-palette-warning-light);
$warning-main: var(--mui-palette-warning-main);
$warning-dark: var(--mui-palette-warning-dark);
$info-light: var(--mui-palette-info-light);
$info-main: var(--mui-palette-info-main);
$info-dark: var(--mui-palette-info-dark);
$success-light: var(--mui-palette-success-light);
$success-main: var(--mui-palette-success-main);
$success-dark: var(--mui-palette-success-dark);

$text-primary: "#ffffff";


