.dialog-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 1rem;

    .logo-wrapper {
        img {
            height: 2.2rem;
        }
    }
    
    .description {
        font-size: 0.8rem;
    }
}

.formatted-dialog {
    .MuiDialog-paper {
        width: 40%;
    }
    .dialog-body {
        padding: 1rem;
    }
    .formatted-title {
        padding-top: 0;
        .title {
            font-size: 1rem;
            color: black
        }
    
        // .text-area-wrapper {
        //     width: 700px;
        // }
    }
    .text-area {
        width: 100%;
        textarea {
            height: 4rem !important;
        }
    } 
}


.dialog-body {
    padding: 2rem;

    .form-wrapper {
        display: block;
    }
}

.MuiDialogActions-root {
    padding: 0 2rem !important;

    button {
        width: 100%;
    }
}

.dialog-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem 0 1.5rem 0;
}